import React from "react";
import { Container, CardDeck, Card, Row, Col } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import Header from "../layouts/navbar";
import { userVerification } from "../../middleware/api";
import { initializeReactGA } from "../../middleware/googleAnalytics";
import "./library.scss";
import CircleLoader from "../loader/loader";

class LibraryDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domainAuth: true,
      loading: true,
      user_id: "",
    };
  }

  async componentDidMount() {

    let check = await userVerification(this.props.match.params.user, this.props.match.params.id );
    sessionStorage.setItem("trakingId", check.gaID);

    //To check the tracking id of GA
    initializeReactGA(this.props.location.pathname);
    if (window.location === window.parent.location) {
      this.setState({domainAuth: false});
    }

    this.setState({
      loading: false,
      user_id: check.user !== "Not authorized" ? check.user : "",
    });
    localStorage.setItem("user", this.props.match.params.user);
    localStorage.setItem("org_id", this.props.match.params.id);  
    localStorage.setItem("user_id", check.user); 
  }


  render() {
    const { loading, user_id, domainAuth } = this.state;

    //To differentiate the version of Loree
    let loreeVersion = this.props.match.params.version;
    sessionStorage.setItem("loreeVersion", loreeVersion);

    if (loading)
      return (
        <CircleLoader width={100} height={100} color={"black"}/>
      );
    // if ((!loading && user_id === "") ||(!domainAuth)) return <Redirect to="/401" />;
    else {
      return (
        <React.Fragment>
          <Header user_id={user_id} user={this.props.match.params.user} data={this.props}/>
          <Container className="margin">
            <CardDeck>
              <Row>
                <Col md={3} sm={6} className="my-3 px-0">
                  <Card className="rounded-lg h-100">
                    <Card.Img
                      src={require("../../assets/images/Accordion.png")}
                      alt="Accordion"
                      className="img-fluid img-thumbnail"
                    ></Card.Img>
                    <div className="interactive-prgs mt-2">
                      <div className="prgs-blue"></div>
                    </div>
                    <Card.Body>
                      <Card.Title className="text-capitalize font-weight-bold">
                        Accordion
                      </Card.Title>
                      <Card.Text>
                      Reduce the amount of text presented to users at a glance, 
                      whilst shortening pages and reducing scrolling.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0 mb-2">
                      <Link
                        to={{
                          pathname: "/form/Accordion",
                          state: {
                            user: this.props.match.params.id,
                            user_id: this.state.user_id,
                          },
                        }}
                        className="float-right btn btn-dark btn-sm btn-pill align-bottom"
                      >
                        Create
                      </Link>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col md={3} sm={6} className="my-3 px-0">
                  <Card className="rounded-lg h-100">
                    <Card.Img
                      src={require("../../assets/images/Tab.png")}
                      alt="Tab"
                      className="img-fluid img-thumbnail"
                    ></Card.Img>
                    <div className="interactive-prgs mt-2">
                      <div className="prgs-red"></div>
                    </div>
                    <Card.Body>
                      <Card.Title className="text-capitalize font-weight-bold">
                        Tab
                      </Card.Title>
                      <Card.Text>
                      Primary goal of tabs is to present users with a grouped view of content, 
                      saving valuable screen real estate.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0 mb-2">
                      <Link
                        to={{
                          pathname: "/form/Tabs",
                          state: {
                            user: this.props.match.params.id,
                            user_id: this.state.user_id,
                          },
                        }}
                        className="float-right btn btn-dark btn-sm btn-pill align-bottom"
                      >
                        Create
                      </Link>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col md={3} sm={6} className="my-3 px-0">
                  <Card className="rounded-lg h-100">
                    <Card.Img
                      src={require("../../assets/images/Button.png")}
                      alt="Button"
                      className="img-fluid img-thumbnail"
                    ></Card.Img>
                    <div className="interactive-prgs mt-2">
                      <div className="prgs-orange"></div>
                    </div>
                    <Card.Body>
                      <Card.Title className="text-capitalize font-weight-bold">
                        Button
                      </Card.Title>
                      <Card.Text>
                      Allow users to take action/make a choice with a single tap.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0 mb-2">
                      <Link
                        to={{
                          pathname: "/addButton",
                          state: {
                            user: this.props.match.params.id,
                            user_id: this.state.user_id,
                          },
                        }}
                        className="float-right btn btn-dark btn-sm btn-pill align-bottom"
                      >
                        Create
                      </Link>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col md={3} sm={6} className="my-3 px-0">
                  <Card className="rounded-lg h-100">
                    <Card.Img
                      src={require("../../assets/images/MultipleChoice.png")}
                      alt="MCQ"
                      className="img-fluid img-thumbnail"
                    ></Card.Img>
                    <div className="interactive-prgs mt-2">
                      <div className="prgs-green"></div>
                    </div>
                    <Card.Body>
                      <Card.Title className="text-capitalize font-weight-bold">
                        Multiple Choice
                      </Card.Title>
                      <Card.Text>
                      Allows users to easily build in a quick skill check.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0 mb-2">
                      <Link
                        to={{
                          pathname: "/multiplechoice",
                          state: {
                            user: this.props.match.params.id,
                            user_id: this.state.user_id,
                          },
                        }}
                        className="float-right btn btn-dark btn-sm btn-pill align-bottom"
                      >
                        Create
                      </Link>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col md={3} sm={6} className="my-3 px-0">
                  <Card className="rounded-lg iCard h-100">
                    <Card.Img
                      src={require("../../assets/images/Click&reveal.png")}
                      alt="Loree-IC"
                      className="img-fluid img-thumbnail"
                    ></Card.Img>
                    <div className="interactive-prgs mt-2">
                      <div className="prgs-blue"></div>
                    </div>
                    <Card.Body>
                      <Card.Title className="text-capitalize font-weight-bold">
                        Click and Reveal Interactions 
                      </Card.Title>
                      <Card.Text>
                      Allows users to offer additional information upon interaction. 
                      Great for a quick skill check.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0 mb-2">
                      <Link
                        to={{
                          pathname: "/form/ClickAndRevealInteractions",
                          state: {
                            user: this.props.match.params.id,
                            user_id: this.state.user_id,
                          },
                        }}
                        className="float-right btn btn-dark btn-sm btn-pill align-bottom"
                      >
                        Create
                      </Link>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col md={3} sm={6} className="my-3 px-0">
                  <Card className="rounded-lg h-100">
                    <Card.Img 
                      src={require("../../assets/images/FlipCard.png")} 
                      alt="FLIPCARD" 
                      className="img-fluid img-thumbnail"
                    ></Card.Img>
                    <div className="interactive-prgs mt-2">
                      <div className="prgs-red"></div>
                    </div>
                    <Card.Body>
                      <Card.Title className="text-capitalize font-weight-bold">Flip Card</Card.Title>
                      <Card.Text>
                      Take advantage of the movement/motion element within this interactive to 
                      increase learner engagement.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0 mb-2">
                      <Link to={{
                        pathname:"/flipcard",
                        state: {
                          user: localStorage.getItem("user"),
                          user_id: sessionStorage.getItem("user_id"),
                        }
                      }} className="float-right btn btn-dark btn-sm btn-pill align-bottom">Create</Link>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col md={3} sm={6} className="my-3 px-0">
                  <Card className="rounded-lg h-100">
                    <Card.Img 
                      src={require("../../assets/images/ImageSlider.png")} 
                      alt="FLIPCARD" 
                      className="img-fluid img-thumbnail"
                    ></Card.Img>
                    <div className="interactive-prgs mt-2">
                      <div className="prgs-orange"></div>
                    </div>
                    <Card.Body>
                      <Card.Title className="text-capitalize font-weight-bold">Video/Image Slider</Card.Title>
                      <Card.Text>
                      Helps to pace delivery of visual information, whilst reducing scrolling.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0 mb-2">
                      <Link
                        to={{
                          pathname: "/form/imageslider",
                          state: {
                            user: this.props.match.params.id,
                            user_id: this.state.user_id,
                          },
                        }} className="float-right btn btn-dark btn-sm btn-pill align-bottom">Create</Link>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col md={3} sm={6} className="my-3 px-0">
                  <Card className="rounded-lg h-100">
                    <Card.Img 
                      src={require("../../assets/images/Drag&Drop.png")} 
                      alt="DND" 
                      className="img-fluid img-thumbnail"
                    ></Card.Img>
                    <div className="interactive-prgs mt-2">
                      <div className="prgs-green"></div>
                    </div>
                    <Card.Body>
                      <Card.Title className="text-capitalize font-weight-bold">Drag and Drop</Card.Title>
                      <Card.Text>
                          Users can drag a particular data and drop it to specific object.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0 mb-2">
                      <Link to={{
                        pathname:"/form/DragandDrop",
                        state: {
                          user: localStorage.getItem("user"),
                          user_id: sessionStorage.getItem("user_id"),
                        }
                      }} className="float-right btn btn-dark btn-sm btn-pill align-bottom">Create</Link>
                    </Card.Footer>
                  </Card>
                </Col>
                <Col md={3} sm={6} className="my-3 px-0">
                  <Card className="rounded-lg h-100">
                    <Card.Img 
                      src={require("../../assets/images/Hotspot.png")} 
                      alt="hotspot" 
                      className="img-fluid img-thumbnail"
                    ></Card.Img>
                    <div className="interactive-prgs mt-2">
                      <div className="prgs-blue"></div>
                    </div>
                    <Card.Body>
                      <Card.Title className="text-capitalize font-weight-bold">Image Hotspot</Card.Title>
                      <Card.Text>
                        Explore the ability to add hotspots that reveal text, images or videos to images that enrich the learning experience.
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0 mb-2">
                      <Link to={{
                        pathname:"/form/Hotspot",
                        state: {
                          user: localStorage.getItem("user"),
                          user_id: sessionStorage.getItem("user_id"),
                        }
                      }} className="float-right btn btn-dark btn-sm btn-pill align-bottom">Create</Link>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </CardDeck>
          </Container>
        </React.Fragment>
      );
    }
  }
}

export default LibraryDashboard;

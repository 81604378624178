/* eslint-disable no-undefined */
import React, { Component } from "react";
import {Container, Button, Row, Col, Toast } from "react-bootstrap";
import {shuffleOptions} from "../shuffle";
import ReactHtmlParser from "react-html-parser";
import { putItems } from "../../../redux/components/reducer.action";
import { fetchContent, viewContent } from "../../../middleware/api";
import { initializeReactGA } from "../../../middleware/googleAnalytics";
import { connect } from "react-redux";
import Header from "../../layouts/navbar";
import { Redirect } from "react-router-dom";
import PostHeightIframe from "../../../middleware/sendHeight";
import Carousel from "./carouselView";
import "../../../assets/scss/dragAndDrop.scss";

let content, response, score = 0;

class DragDrop extends Component {

  constructor(props) {
    super(props);
    this.state = {
      viewpage: this.props.location.pathname,
      image: [],
      dragText: [], 
      checkAnswered:false,
      check: true,
      getResult: false,
      tryAgain: false,
      finalResults: false,
      score: 0,
      index:0,
      next: false
    };
  }

  async componentDidMount(){
    const { PutItems } = this.props;
    const { id } = this.props.match.params;
    const { viewpage } = this.state;

    if (
      viewpage === `/DragandDrop/${id}` ||
      `/dradanddrop/${id}`
    ) {
      response = await viewContent(id);
    } else {
      if (
        this.props.match.params.user === undefined ||
        this.props.match.params.user === ""
      ) {
        content = sessionStorage.getItem("user_id");
      } else {
        content = this.props.match.params.user;
      }
      response = await fetchContent(id, content);
    }
    if (!response.message && response.library_id === 8) {

      //To check the tracking id of GA
      if(this.state.viewpage !== `/DragandDrop/${id}` ) initializeReactGA(viewpage);
      
      PutItems({
        title: response.title,
        parameters: JSON.parse(response.parameters),
        customisations: response.customisations,
      });
    } else {
      this.props.history.push("/*");
    }

    if(this.state.viewpage === `/DragandDrop/${id}` ) PostHeightIframe(response.library_id, "rendering");

    let shuffledText = [], imagesrc = [];
    if (response.customisations.dndLayout === "Fixed") {
      this.props.parameters.map((content) => {
        shuffledText.push(content.title);
        return null;
      });
    } else if(response.customisations.dndLayout === "Overlay") {
      this.props.parameters.map((content) => {
        let data = ReactHtmlParser(content.text);
        data.map((children) => {
          let image = children.props.children;
          image.map((source)=>{
            let finalValue = {title: content.title, src: source.props.src };
            imagesrc.push(finalValue);
            return null;
          });
          return null;
        });
        shuffledText.push(content.title);
        return null;
      });
      this.setState({image: imagesrc});
    }else{
      this.props.parameters.map((image) => {
        image.dropZone.map((position) => {
          shuffledText.push(position.data.text);
          return null;
        });
        return null;
      });
    }

    shuffledText= shuffleOptions(
      shuffledText
    );
    this.setState({dragText: shuffledText});
  }

onDragStart = (event) => {
  const {customisations} = this.props;
  event.dataTransfer.setData("text", event.target.id);
  const layout = (customisations.dndLayout === "Fixed") ? "droptarget" : "drop";
  const dropArea = document.getElementsByClassName(layout);
  Array.from(dropArea).forEach((element) => {
    element.style.border = "2px dashed grey";
  });
}
onDragOver = (event) => {
  event.preventDefault();
}

onDragEnter = (event) => {
  if(event.target.type !== "button"){event.target.style.border = "3px dashed grey";}
}

onDragLeave = (event) => {
  event.target.style.border = "";
}

onDrop = (event) => {
  event.preventDefault();
  const {customisations} = this.props;
  event.preventDefault();
  const layout = (customisations.dndLayout === "Fixed") ? "droptarget" : "drop";
  const dropArea = document.getElementsByClassName(layout);
  Array.from(dropArea).forEach((element) => {
    element.style.border = "";
  });
  let target = event.target;
  let btnsHolder = document.getElementById("buttonsHolder");
  let currentBtn;
  var data = event.dataTransfer.getData("text");
  //check if there's already a button inside
  if (target.children.length > 0){
    currentBtn = target.children[0];
    btnsHolder.appendChild(currentBtn);
  }
  if(target !== document.getElementById(data) && target.type !== "button") {
    target.appendChild(document.getElementById(data));
    event.target.style.border = "";
  }
}

checkAnswer = () => {
  const { parameters, customisations } = this.props;
  let dropValues = [], dragArea = parameters[this.state.index].dropZone ;
  
  if(customisations.dndLayout === "CustomOverlay"){
    dragArea.map((element)=>{
      let component = document.getElementById(element.data.text);
      let innerText = component.textContent;
      if(innerText === ""){
        this.setState({checkAnswered:true});
        component.style.border = "3px dotted #9dd8bb";
      }
      else {
        dropValues.push(innerText);
      }
      return null;
    });
    if(dropValues.length === dragArea.length){
      this.checkCorrectAnswer();
    }
  }else{
    parameters.map((data)=>{
      let component = document.getElementById(data.title);
      let innerText = component.textContent;
      if(innerText === ""){
        this.setState({checkAnswered:true});
        component.style.border = "3px dotted #9dd8bb";
      }
      else {
        dropValues.push(innerText);
      }
      return null;
    });
    if(dropValues.length === parameters.length){
      this.checkCorrectAnswer();
    }
  }
}

checkCorrectAnswer = () => {
  const {parameters, customisations} = this.props;
  let correct = [], wrong = [], dragArea= parameters[this.state.index].dropZone;
  const element = document.getElementById("dragdrop");
  element.style.pointerEvents = "none";
  const droptarget = document.getElementById("buttonsHolder");
  droptarget.style.border = "none";

  // To check the correct Answer for CustomOverlay Layout
  if(customisations.dndLayout === "CustomOverlay"){
    dragArea.map((element)=>{
      let component = document.getElementById(element.data.text);
      let innerText = component.textContent;
      if(innerText === component.id){
        correct.push(innerText);
        let icon = `<i class="fas fa-check ${customisations.dndLayout === "Fixed" ? "fixed-tick-circle": "overlay-tick-circle"}"></i>`;
        component.children[0].style.background="#9dd8bb";
        component.children[0].innerHTML += icon;
      } else{
        wrong.push(innerText);
        let icon = `<i class="fas fa-times ${customisations.dndLayout === "Fixed" ? "fixed-cross-circle" : "overlay-cross-circle"}"></i>`;
        component.children[0].style.background="#f7d0d0";
        component.children[0].innerHTML += icon;          
      }
      return null;
    });
    // score calculation
    let currentScore = (correct.length/dragArea.length * 100).toFixed();
    score = score + parseInt(currentScore);
    if(this.state.index+1 === parameters.length){
      let finalScore =( score/parameters.length).toFixed();
      finalScore = parseInt(finalScore);
      this.setState({getResult: true, check: false, score: finalScore});
    }
    else{this.setState({next:true, check: false});}
  }else{
    parameters.map((data)=>{
      let component = document.getElementById(data.title);
      let innerText = component.textContent;
      if(innerText === data.title){
        correct.push(data.title);
        let icon = `<i class="fas fa-check ${customisations.dndLayout === "Fixed" ? "fixed-tick-circle": "overlay-tick-circle"}"></i>`;
        if(customisations.dndLayout === "Fixed") {
          component.style.background="#9dd8bb";
          component.innerHTML += icon;
          component.children[0].classList.add("viewAnswers");
        }else {
          component.children[0].style.background = "#9dd8bb";
          component.children[0].classList.add("position-absolute");
          component.children[0].innerHTML += icon;
        }
  
      } else{
        wrong.push(innerText);
        let icon = `<i class="fas fa-times ${customisations.dndLayout === "Fixed" ? "fixed-cross-circle" : "overlay-cross-circle"}"></i>`;
        if(customisations.dndLayout === "Fixed") {
          component.style.background="#f7d0d0";
          component.innerHTML += icon;
          component.children[0].classList.add("viewAnswers");
        } else {
          component.children[0].style.background = "#f7d0d0";
          component.children[0].classList.add("position-absolute");
          component.children[0].innerHTML += icon;
        }
      }
      return null;
    });
    let score = (correct.length/parameters.length * 100).toFixed();
    score = parseInt(score);
    this.setState({score: score, check:false,  getResult: true});
  }
}

nextSlide = () =>{
  const element = document.getElementById("dragdrop");
  const droptarget = document.getElementById("buttonsHolder");
  element.removeAttribute("style");
  droptarget.removeAttribute("style");
  this.setState({ 
    index: this.state.index+1,
    next: false,
    check: true
  });
}

getResult = () => {
  if(this.state.viewpage === `/DragandDrop/${this.props.match.params.id}` ) PostHeightIframe(response.library_id);
  if(this.state.score === 100){
    this.setState({finalResults: true});
  }else{
    score = 0;
    this.setState({tryAgain: true});
  }
}

tryAgain = () => {
  if(this.state.viewpage === `/DragandDrop/${this.props.match.params.id}` ) PostHeightIframe(response.library_id);
  this.setState({
    index: 0,
    tryAgain: false,
    getResult: false,
    finalResults: false,
    check: true
  });
}

//Edit functionality for accordion
handleEdit = () => {
  let path;
  if (this.props.match.params.user === undefined) {
    path = "/edit/DragandDrop";
  } else {
    path = `/content/DragandDrop/${this.props.match.params.user}`;
  }
  const { id } = this.props.match.params;
  if (id) {
    this.props.history.push({
      pathname: `${path}/${id}/${sessionStorage.getItem("org_id")}/${sessionStorage.getItem("loreeVersion")}`,
      state: {
        user:
          this.props.location.state !== undefined
            ? this.props.location.state.user
            : "",
        user_id:
          this.props.location.state !== undefined
            ? this.props.location.state.user_id
            : "",
      },
    });
  }
};

hoverOn=(id)=>{
  let element = document.getElementById(id);
  element.style.background = this.props.customisations.bgcolor;
  element.style.color = this.props.customisations.fontColor;
  this.setState({ hover: true });
}

//mouse leave from button
hoverOff=(id)=>{ 
  let element = document.getElementById(id);
  element.style.background = "white";
  element.style.color = "#000000";
  this.setState({ hover: false });    
}

render() {
  const { title, parameters, customisations } = this.props;
  const { image, getResult, tryAgain, score, finalResults, next, check } = this.state;
  const { id } = this.props.match.params;

  // Values for button Customisation
  const mouseover= customisations.dragAreaType === "btn btn-outline-dark" ? true : false;
  const buttonStyle = {
    background: customisations.bgcolor ? `${customisations.bgcolor}` : "#F2F2F2", 
    fontSize: customisations.fontSize ? `${customisations.fontSize}px` : "16px",
    border:"2px solid"+ customisations.bgcolor ? `${customisations.bgcolor}` : "#F2F2F2",
    fontFamily: customisations.fontFamily ? `${customisations.fontFamily}` : "Helvetica", 
    color:  customisations.fontColor ? `${customisations.fontColor}` : "#000000",
    paddingTop: (customisations.paddingTop === "") ? 0 : customisations.paddingTop ? `${customisations.paddingTop}px`:"10px",
    paddingLeft: (customisations.paddingLeft === "") ? 0 : customisations.paddingLeft ? `${customisations.paddingLeft}px` : "10px",
    paddingBottom: (customisations.paddingBottom === "") ? 0 : customisations.paddingBottom ? `${customisations.paddingBottom}px` : "10px",
    paddingRight: (customisations.paddingRight === "") ? 0 : customisations.paddingRight ? `${customisations.paddingRight}px` :"10px"
  };

  const buttonUnhover ={
    background: "white",
    fontSize: customisations.fontSize ? `${customisations.fontSize}px` : "16px",
    border:"2px solid"+ customisations.bgcolor ? `${customisations.bgcolor}` : "#F2F2F2",
    fontFamily: customisations.fontFamily ? `${customisations.fontFamily}` : "Helvetica", 
    color: "#000000",
    paddingTop: (customisations.paddingTop === "") ? 0 : customisations.paddingTop ? `${customisations.paddingTop}px`:"10px",
    paddingLeft: (customisations.paddingLeft === "") ? 0 : customisations.paddingLeft ? `${customisations.paddingLeft}px` : "10px",
    paddingBottom: (customisations.paddingBottom === "") ? 0 : customisations.paddingBottom ? `${customisations.paddingBottom}px` : "10px",
    paddingRight: (customisations.paddingRight === "") ? 0 : customisations.paddingRight ? `${customisations.paddingRight}px` :"10px"
  };
 
  if (this.props.location.pathname === `/contentview/DragandDrop/${this.props.match.params.user}/${id}` || this.props.location.pathname === `/create/new/DragandDrop/${id}` ){
    if(this.props.location.state ){
      return (
        <React.Fragment>
          <Header
            user={localStorage.getItem("user")}
            user_id={sessionStorage.getItem("user_id")}
            data={this.props}
          />
          <Toast
            onClose={()=>this.setState({checkAnswered:false})}
            show={this.state.checkAnswered}
            delay={5000}
            autohide
            className="toaster-alert"
          >
            <Toast.Header>
              <strong className='mr-auto'>Please fill all answers</strong>
            </Toast.Header>
          </Toast>
          {(tryAgain === true) ?
            <Container className="margin">
              <div className="tryAgain col-md-12">
           You have Scored {score}%
                <br/>
                <Button className="mt-3" onClick={this.tryAgain}>Try again <i className="fas fa-redo"></i></Button>
              </div> 
            </Container> : 
            (finalResults === true) ?
              <Container className="margin">
                <div className="col-md-12 finalResults animation">
                  <div className="before"></div>
                  <div className="after"></div>
           Congratulations
                  <br/>
           You have Scored 100%
                </div> 
              </Container> :
              <Container className="margin">
                <Row className="d-flex justify-content-between mt-3">
                  <Col style={{wordBreak:"break-word"}}>
                    <h2>{title}</h2>
                  </Col>
                  <Col xs lg="1">
                    <Button className="btn btn-dark" onClick={this.handleEdit}>
                    Edit
                    </Button>
                  </Col>
                </Row>
                {(customisations.dndLayout !== "CustomOverlay") ? 
                  <div id = "dragdrop">
                    <Row>
                      {
                        (customisations.dndLayout === "Fixed") ?
                          parameters.map((content,idx) => (
                            <div className="col-md-3 mt-5 drag" key={`img_${idx}`}>
                              {ReactHtmlParser(content["text"])}
                              <div id={content["title"]} className="droptarget mt-n1" onDrop={this.onDrop} onDragOver={this.onDragOver} 
                                onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave}></div>
                            </div>
                          )) :   
                          image.map((content,idx) => (
                            <div id={content.title} key={`img_${idx}`}  
                              style={{backgroundImage:`url("${content.src}")`, backgroundSize:"contain", backgroundRepeat:"no-repeat"}} 
                              className="drop col-md-2 m-2" onDrop={this.onDrop} onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} 
                              onDragLeave={this.onDragLeave}>
                            </div>
                          ))
                      }
                    </Row> 
                    <div className={`row ${customisations.dndLayout === "Fixed" ? "mt-4" : "col-md-11 mt-4"}`} id="buttonsHolder" 
                      onDrop={this.onDrop} onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} 
                      onDragLeave={this.onDragLeave}>
                      {this.state.dragText.map((content,idx) => (
                        mouseover ? <Button type="button" className={`${customisations.dragAreaType} drop-btn mr-2`} 
                          style={buttonUnhover}  key={`key_${idx}`} id={`key_${idx}`} 
                          draggable="true" onDragStart={this.onDragStart} onMouseEnter={this.hoverOn.bind(this,`key_${idx}`)} 
                          onMouseLeave={this.hoverOff.bind(this,`key_${idx}`)} 
                        >{content}</Button> : 
                          <Button type="button" key={`key_${idx}`} id={`key_${idx}`}  className={`${customisations.dragAreaType} drop-btn mr-2`} 
                            style={buttonStyle} draggable="true" onDragStart={this.onDragStart} >{content}
                          </Button>
                      ))}
                    </div> 
                  </div> : 
                  <div id = "dragdrop" className="text-center mt-2">
                    <Carousel idx={this.state.index} length={parameters.length} data = {parameters[this.state.index]} events={{hoverOn: this.hoverOn, hoverOff: this.hoverOff}}
                      customisations={{customisation: this.props.customisations, buttonStyle: buttonStyle, buttonUnhover: buttonUnhover, mouseover: mouseover}}/>
                  </div>
                }
                {(check ? <Button id = "checkAnswer" className="mt-5" onClick={this.checkAnswer}>Check Answer</Button> : "")}
                {(next === true)?
                  <div id = "next">
                    <Button id = "checkAnswer" className="mt-5" onClick={this.nextSlide}>Next</Button>
                  </div> : ""
                }
                {(getResult === true)?
                  <div id = "tryAgain">
                    <Button className="mt-5 results" onClick={this.getResult}>Get Results </Button>
                  </div> : ""
                }
              </Container>
          }
        </React.Fragment>
      );
    }else {
      return <Redirect to="/401" />;
    }
  }else if (this.props.location.pathname === `/draganddrop/${id}` || this.props.location.pathname === `/DragandDrop/${id}`) {
    return(
      <React.Fragment>
        <Toast
          onClose={()=>this.setState({checkAnswered:false})}
          show={this.state.checkAnswered}
          delay={5000}
          autohide
          className="toaster-alert"
        >
          <Toast.Header>
            <strong className='mr-auto'>Please fill all answers</strong>
          </Toast.Header>
        </Toast>
        {(tryAgain === true) ?
          <div className="tryAgain container col-md-12">
       You have scored {score}%
            <br/>
            <Button className="mt-3" onClick={this.tryAgain}>Try again <i className="fas fa-redo"></i></Button>
          </div> : 
          (finalResults === true) ?
            <div className="col-md-12 finalResults animation">
              <div className="before"></div>
              <div className="after"></div>
       Congratulations
              <br/>
       You have scored 100%
            </div> 
            :
            <Container>
              {(customisations.dndLayout !== "CustomOverlay") ? 
                <div id = "dragdrop">
                  <Row>
                    {
                      (customisations.dndLayout === "Fixed") ?
                        parameters.map((content,idx) => (
                          <div className="col-md-3 mt-5 drag" key={`img_${idx}`}>
                            {ReactHtmlParser(content["text"])}
                            <div id={content["title"]} className="droptarget mt-n1" onDrop={this.onDrop} onDragOver={this.onDragOver} 
                              onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave}></div>
                          </div>
                        )) :   
                        image.map((content,idx) => (
                          <div id={content.title} key={`img_${idx}`}  
                            style={{backgroundImage:`url("${content.src}")`, backgroundSize:"contain", backgroundRepeat:"no-repeat"}} 
                            className="drop col-md-2 m-2" onDrop={this.onDrop} onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} 
                            onDragLeave={this.onDragLeave}>
                          </div>
                        ))
                    }
                  </Row> 
                  <div className={`row ${customisations.dndLayout === "Fixed" ? "mt-4" : "col-md-11"}`} id="buttonsHolder" 
                    onDrop={this.onDrop} onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} 
                    onDragLeave={this.onDragLeave}>
                    {this.state.dragText.map((content,idx) => (
                      mouseover ? <Button type="button" className={`${customisations.dragAreaType} drop-btn mr-2`} 
                        style={buttonUnhover}  key={`key_${idx}`} id={`key_${idx}`} 
                        draggable="true" onDragStart={this.onDragStart} onMouseEnter={this.hoverOn.bind(this,`key_${idx}`)} 
                        onMouseLeave={this.hoverOff.bind(this,`key_${idx}`)} 
                      >{content}</Button> : 
                        <Button type="button" key={`key_${idx}`} id={`key_${idx}`}  className={`${customisations.dragAreaType} drop-btn mr-2`} 
                          style={buttonStyle} draggable="true" onDragStart={this.onDragStart} >{content}
                        </Button>
                    ))}
                  </div> 
                </div> : 
                <div id = "dragdrop" className="text-center mt-2">
                  <Carousel idx={this.state.index} length={parameters.length} data = {parameters[this.state.index]} events={{hoverOn: this.hoverOn, hoverOff: this.hoverOff}}
                    customisations={{customisation: this.props.customisations, buttonStyle: buttonStyle, buttonUnhover: buttonUnhover, mouseover: mouseover}}/>
                </div>
              }
              {(check ? <Button id = "checkAnswer" className="mt-5" onClick={this.checkAnswer}>Check Answer</Button> : "")}
              {(next === true)?
                <div id = "next">
                  <Button id = "checkAnswer" className="mt-5" onClick={this.nextSlide}>Next</Button>
                </div> : ""
              }
              {(getResult === true)?
                <div id = "tryAgain">
                  <Button className="mt-5 results" onClick={this.getResult}>Get Results </Button>
                </div> : ""
              }
            </Container>
        }
      </React.Fragment>
    );
  }else {
    return <Redirect to="/401" />;
  }
}
}

//state from redux
const mapStateToProps = ({ component }) => ({
  title: component.title,
  parameters: component.parameters,
  customisations: component.customisations
});

const mapDispatchToProps = dispatch => ({
  PutItems: component => dispatch(putItems(component))
});

//connecting form with redux
export default connect(mapStateToProps, mapDispatchToProps)(DragDrop);

function PostHeightIframe(libraryId, render) {
  let delayTime = render ?  3000 : 900
  setTimeout(() => {
    var elemHeight = document.getElementById("root").clientHeight;
    var event = {
      type: "LOREE_WINDOW_SIZE",
      height: (libraryId === 4 || libraryId === 9 || libraryId === 8) ? elemHeight + 75 : libraryId === 5 ? elemHeight + 40 : elemHeight + 30,
      url: window.location.href
    };
    var canvasWindow = window.parent;
    let url = document.referrer;
 
    if (url !== "" && document.referrer.indexOf(url) >= 0) {
      canvasWindow.postMessage(event, url);
    }
  }, delayTime);
}

export default PostHeightIframe;
